<template>
    <div class="wrapper">
        <div class="card-form">
            <div class="card-list">
                <div class="card-item" v-bind:class="{ '-active' : isCardFlipped }">
                    <div class="card-item__side -front">
                        <div
                            class="card-item__focus"
                            v-bind:class="{'-active' : focusElementStyle }"
                            v-bind:style="focusElementStyle"
                            ref="focusElement"
                        ></div>
                        <div class="card-item__cover">
                            <!-- <img
                                v-bind:src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + currentCardBackground + '.jpeg'"
                                class="card-item__bg"
                            > -->
                        </div>

                        <div class="card-item__wrapper">
                            <div class="card-item__top">
                                <img src="@/assets/images/chip.png" class="card-item__chip">
                                <div class="card-item__type">
                                    <transition name="slide-fade-up">
                                        <img
                                            v-if="getCardType"
                                            :src="require('@/assets/images/' + getCardType + '.png')"
                                            :key="getCardType"
                                            class="card-item__typeImg"
                                        >
                                    </transition>
                                </div>
                            </div>
                            <label for="cardNumber" class="card-item__number" ref="cardNumber">
                                <template v-if="getCardType === 'amex'">
                                    <span v-for="(n, $index) in amexCardMask" :key="$index">
                                        <transition name="slide-fade-up">
                                            <div
                                                class="card-item__numberItem"
                                                v-if="$index > 4 && $index < 14 && cardNumber.length > $index && n.trim() !== ''"
                                            >*</div>
                                            <div
                                                class="card-item__numberItem"
                                                :class="{ '-active' : n.trim() === '' }"
                                                :key="$index" v-else-if="cardNumber.length > $index"
                                            >
                                                {{cardNumber[$index]}}
                                            </div>
                                            <div
                                                class="card-item__numberItem"
                                                :class="{ '-active' : n.trim() === '' }"
                                                v-else
                                                :key="$index + 1"
                                            >{{n}}</div>
                                        </transition>
                                    </span>
                                </template>

                                <template v-else>
                                    <span v-for="(n, $index) in otherCardMask" :key="$index">
                                        <transition name="slide-fade-up">
                                            <div
                                                class="card-item__numberItem"
                                                v-if="$index > 4 && $index < 15 && cardNumber.length > $index && n.trim() !== ''"
                                            >*</div>
                                            <div class="card-item__numberItem"
                                                :class="{ '-active' : n.trim() === '' }"
                                                :key="$index" v-else-if="cardNumber.length > $index">
                                                {{cardNumber[$index]}}
                                            </div>
                                            <div
                                                class="card-item__numberItem"
                                                :class="{ '-active' : n.trim() === '' }"
                                                v-else
                                                :key="$index + 1"
                                            >{{n}}</div>
                                        </transition>
                                    </span>
                                </template>
                            </label>
                            <div class="card-item__content">
                                <label for="cardName" class="card-item__info" ref="cardName">
                                    <div class="card-item__holder">Titular do cartão</div>
                                    <transition name="slide-fade-up">
                                        <div class="card-item__name" v-if="cardName.length" key="1">
                                            <transition-group name="slide-fade-right">
                                                <span
                                                    class="card-item__nameItem"
                                                    v-for="(n, $index) in cardName.replace(/\s\s+/g, ' ')"
                                                    v-bind:key="$index + 1"
                                                >
                                                    <div v-if="$index === $index">{{n}}</div>
                                                </span>
                                            </transition-group>
                                        </div>
                                        <div class="card-item__name" v-else key="2">Nome Completo</div>
                                    </transition>
                                </label>
                                <div class="card-item__date" ref="cardDate">
                                    <label for="cardMonth" class="card-item__dateTitle">Validade</label>
                                    <label for="cardMonth" class="card-item__dateItem">
                                        <transition name="slide-fade-up">
                                            <span v-if="cardMonth" v-bind:key="cardMonth">{{cardMonth}}</span>
                                            <span v-else key="2">MM</span>
                                        </transition>
                                    </label>
                                    /
                                    <label for="cardYear" class="card-item__dateItem">
                                        <transition name="slide-fade-up">
                                            <span v-if="cardYear" v-bind:key="cardYear">{{String(cardYear).slice(2,4)}}</span>
                                            <span v-else key="2">YY</span>
                                        </transition>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-item__side -back">
                        <div class="card-item__cover">
                            <!-- <img
                                v-bind:src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + currentCardBackground + '.jpeg'"
                                class="card-item__bg"
                            > -->
                        </div>
                        <div class="card-item__band"></div>
                        <div class="card-item__cvv">
                            <div class="card-item__cvvTitle">CVV</div>
                            <div class="card-item__cvvBand">
                                <span v-for="(n, $index) in cardCvv" :key="$index">
                                    *
                                </span>
                            </div>
                            <div class="card-item__type">
                                <img
                                    v-if="getCardType"
                                    :src="require('@/assets/images/' + getCardType + '.png')"
                                    class="card-item__typeImg"
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-form__inner">
                <div class="card-input">
                    <!-- <label for="cardNumber" class="card-input__label">Número do cartão</label> -->
                    <v-text-field
                        label="Número do cartão"
                        v-model="cardNumber"
                        hide-details
                        id="cardNumber"
                        v-mask="generateCardNumberMask"
                        @focus="focusInput"
                        @blur="blurInput"
                        data-ref="cardNumber"
                        outlined
                    />
                </div>
                <div class="card-input">
                    <!-- <label for="cardName" class="card-input__label">Titular do cartão</label> -->
                    <v-text-field
                        label="Titular do cartão"
                        id="cardName"
                        v-model="cardName"
                        hide-details
                        @focus="focusInput"
                        @blur="blurInput"
                        data-ref="cardName"
                        outlined
                    />
                </div>
                <div class="card-form__row">
                    <div class="card-form__col">
                        <div class="card-form__group">
                        <label for="cardMonth" class="card-input__label">Data de validade</label>
                        <select
                            class="card-input__input -select"
                            id="cardMonth"
                            v-model="cardMonth"
                            @focus="focusInput"
                            @blur="blurInput"
                            data-ref="cardDate"
                        >
                            <option value="" disabled selected>Mês</option>
                            <option
                                :value="n < 10 ? '0' + n : n"
                                v-for="n in 12"
                                :disabled="n < minCardMonth"
                                :key="n"
                            >
                                {{n >= 10 ? n : '0' + n}}
                            </option>
                        </select>
                        <select
                            class="card-input__input -select"
                            id="cardYear"
                            v-model="cardYear"
                            v-on:focus="focusInput"
                            v-on:blur="blurInput"
                            data-ref="cardDate"
                        >
                            <option value="" disabled selected>Ano</option>
                            <option
                                :value="$index + minCardYear"
                                v-for="(n, $index) in 12"
                                :key="n"
                            >
                                {{$index + minCardYear}}
                            </option>
                        </select>
                        </div>
                    </div>
                    <div class="card-form__col -cvv">
                        <div class="card-input">
                            <!-- <label for="cardCvv" class="card-input__label">CVV</label> -->
                            <v-text-field
                                label="CVV"
                                id="cardCvv"
                                v-mask="'####'"
                                maxlength="4"
                                v-model="cardCvv"
                                hide-details
                                @focus="flipCard(true)"
                                @blur="flipCard(false)"
                                outlined
                            />
                        </div>
                    </div>
                </div>

                <button class="card-form__button" @click="confirmPayment">Confirmar</button>
            </div>
        </div>
    </div>
</template>

<script>
// https://codepen.io/JavaScriptJunkie/pen/YzzNGeR
export default {
    name: 'Account',
    data: () => ({
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
        minCardYear: new Date().getFullYear(),
        amexCardMask: '#### ###### #####',
        otherCardMask: '#### #### #### ####',
        cardNumberTemp: '',
        isCardFlipped: false,
        focusElementStyle: null,
        isInputFocused: false
    }),
    created() {
        this.cardNumberTemp = this.otherCardMask;
    },
    computed: {
        getCardType () {
            let number = this.cardNumber;
            if (number.match(new RegExp('^4')) != null) {
                return 'visa';
            }

            if (number.match(new RegExp('^(34|37)')) != null) {
                return 'amex';
            }

            if (number.match(new RegExp('^5[1-5]')) != null) {
                return 'mastercard';
            }

            if (number.match(new RegExp('^6011')) != null) {
                return 'discover';
            }

            if (number.match(new RegExp('^9792')) != null) {
                return 'troy'
            }

            return ''; // default type
            // return 'visa'; // default type
        },
		generateCardNumberMask () {
			return this.getCardType === 'amex' ? this.amexCardMask : this.otherCardMask;
        },
        minCardMonth () {
            if (this.cardYear === this.minCardYear) {
                return new Date().getMonth() + 1;
            }
            return 1;
        },
        getCardData () {
            return {
                cardName: this.cardName,
                cardNumber: this.cardNumber,
                cardMonth: this.cardMonth,
                cardYear: this.cardYear,
                cardCvv: this.cardCvv,
                brand: this.getCardType,
            };
        },
    },
    watch: {
        cardYear () {
            if (this.cardMonth < this.minCardMonth) {
                this.cardMonth = '';
            }
        }
    },
    methods: {
        confirmPayment () {
            if (!this.cardNumber) {
                this.notify('Informe o número do cartão', 'warning');
                return;
            }
            if (!this.cardName) {
                this.notify('Informe o nome do titular do cartão', 'warning');
                return;
            }
            if (!this.cardMonth) {
                this.notify('Informe o mês de validade do cartão', 'warning');
                return;
            }
            if (!this.cardYear) {
                this.notify('Informe o ano de validade do cartão', 'warning');
                return;
            }
            if (!this.cardCvv) {
                this.notify('Informe o código de verificação do cartão', 'warning');
                return;
            }
            this.$emit('onConfirm', this.getCardData);
        },
        flipCard (status) {
            this.isCardFlipped = status;
        },
        focusInput (e) {
            this.isInputFocused = true;
            let targetRef = e.target.dataset.ref;
            let target = this.$refs[targetRef];
            this.focusElementStyle = {
                width: `${target.offsetWidth}px`,
                height: `${target.offsetHeight}px`,
                transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`
            }
        },
        blurInput() {
            let vm = this;
            setTimeout(() => {
                if (!vm.isInputFocused) {
                    vm.focusElementStyle = null;
                }
            }, 300);
            vm.isInputFocused = false;
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "./card-checkout.scss";
</style>
